import React from 'react';
import {Link} from 'gatsby';
import Save from 'file-saver';
import Layout from '../layouts';
import Moment from 'moment';
import {FormControl, Tabs, Tab, Nav, NavItem, Table, ButtonToolbar, DropdownButton, MenuItem, Glyphicon, Button, Row, Col} from 'react-bootstrap';

export default class Component extends React.Component {
  state = {error: false}

  getSpecs = (e) => {
    e.preventDefault();
    fetch(`https://api.pourmgmt.com/commerce/specs?password=${this.password.value}`, {
      headers: {
        'Content-Type': 'text/csv'
      },
      mode: 'no-cors',
      responseType: 'blob'
    }).then(response => response.blob()).then(blob => {
      Save(blob, `Pour_Management_Products_${Moment(new Date()).format('MM-DD-YYYY')}.csv`)
    }).catch(err => this.setState({error: true}))
    this.password.value = '';
  }

  wineRows = (products) => {
    return products.map(product =>
      <tr>
        <td>{product.SKU}</td>
        <td>{product.Wine.Name}</td>
        <td>{product.Vintage}</td>
        <td>{(product['Varietal(s)'] || []).map((v, i) => i ? ` - ${v}` : v)}</td>
        <td style={{textAlign: 'center'}}>
          {product['Tech Sheets'] && product['Tech Sheets'].length ? <Button onClick={() => this.save(product['Tech Sheets'])} aria-label="Save Tech Sheet"><Glyphicon glyph="save" /></Button> : null}
        </td>
        <td style={{textAlign: 'center'}}>
          {product['Shelf Talker'] && product['Shelf Talker'].length ? <Button onClick={() => this.save(product['Shelf Talker'])} aria-label= "Save Shelf Talker"><Glyphicon glyph="save" /></Button> : null}
        </td>
        <td style={{textAlign: 'center'}}>
          {product['Product Shots'] && product['Product Shots'].length ? <Button onClick={() => this.save(product['Product Shots'])} aria-label= "Save Product Shots"><Glyphicon glyph="save" /></Button> : null}
        </td>
        <td style={{textAlign: 'center'}}>
          {product['Labels'] && product['Labels'].length ? <Button onClick={() => this.save(product['Labels'])} aria-label= "Save Labels"><Glyphicon glyph="save" /></Button> : null}
        </td>
      </tr>
    )
  }

  distributorRows = (distributors) => {
    return distributors.map(distributor =>
      <div>
        <h4 style={{marginBottom: '5px', fontSize: '18px', marginTop: '20px', lineHeight: '26px'}}>
          {(distributor.Markets || []).map((market, i) =>
            <div>{market}<br/></div>
          )}
        </h4>
        <p style={{fontSize: '16px'}}>
        {distributor.Website ? <a href={distributor.Website} target="_blank">{distributor.Name}<br/></a> : <span>{distributor.Name}<br/></span>}
        {distributor.Email ? <span>{distributor.Email}<br/></span> : null}
        {distributor.Phone ? <span>{distributor.Phone}<br/></span> : null}
        </p>
      </div>
    )
  }

  save = (files) => {
    for (let i = 0; i < files.length; i++)
      Save(files[i].url, files[i].filename);
  }

  render() {
    return (
  <Layout nav="trade" >
    <Row>
      <Col md={8} mdOffset={2}>
        <Tab.Container defaultActiveKey="1" id="trade">
          <div>
            <Nav className="pro-info-tab-list section" bsStyle="tabs" bsClass="test1">
              <NavItem eventKey="1">Wholesalers</NavItem>
              <NavItem eventKey="2">Wines</NavItem>
              {/*<NavItem eventKey="3">Specs</NavItem>*/}
            </Nav>
        <Tab.Content animation>
          <Tab.Pane eventKey="1">
          <div className="services-text">
            {this.distributorRows(this.props.pageContext.distributors || [])}
          </div>
          </Tab.Pane>
          <Tab.Pane eventKey="2">
          <div className="contact-form-titile text-center" style={{margin: '10px 0px 5px 0px'}}>
            <h3 style={{lineHeight: '36px', textTransform: 'none', fontSize: '20px', margin: '0px'}}>Wines currently in stock</h3>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Wine</th>
                <th>Vintage</th>
                <th>Varietal</th>
                <th>Tech Sheet</th>
                <th>Shelf Talker</th>
                <th>Bottle</th>
                <th>Label</th>
              </tr>
            </thead>
            <tbody>
            {this.wineRows(this.props.pageContext.wines || [])}
            </tbody>
          </Table>
          </Tab.Pane>
          <Tab.Pane eventKey="3">
          <div className="contact-form-titile text-center" style={{margin: '20px 0px 20px 0px'}}>
            <h3 style={{lineHeight: '26px', textTransform: 'none', fontSize: '16px', margin: '0px'}}>Enter password and click download to access details specs for wines currently in stock</h3>
          </div>
          <div style={{textAlign: 'center', minHeight: '250px'}}>
          <FormControl
            placeholder="Enter Password"
            style={{display: 'inline-block', width: '300px'}}
            inputRef={ref => { this.password = ref; }}
            type="password"
            aria-label="Password"
          />
            <Button href="#" onClick={this.getSpecs} style={{height: '33px', verticalAlign: 'top', marginLeft: '10px', display: 'inline-block'}}>Download <Glyphicon glyph="save" /></Button>
            {this.state.error ? <h3 style={{marginTop: '20px', lineHeight: '26px', textTransform: 'none', fontSize: '16px', color: 'red'}}>Incorrect Password</h3> : null}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </div>
      </Tab.Container>
      <div className="contact-form-titile text-center" style={{marginBottom: 0}}>
        <h3 style={{marginTop: '30px', marginBottom: 0, lineHeight: '36px', textTransform: 'none', fontSize: '20px'}}>Want to meet up or do an event? <Link to="/contact">Contact us</Link></h3>
      </div>
    </Col>
    </Row>
  </Layout>
    )
  }
}
